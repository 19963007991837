.slide {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    // flex-direction: row-reverse;

    &_standard {
        flex-direction: row-reverse;
    }
    &_reverse {
        flex-direction: row;

    }
    
    &__bg-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        position: absolute;
        opacity: 0.05;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    }
}

.scroll-down-img-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
    height: 165.3px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.scroll-down-img {
    // z-index: 9;
    width: auto;
    height: 60%;
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

    .scroll-down-img {
        display: none;
    }
}
  
@media (min-width: 769px) and (max-width: 992px) {
    .scroll-down-img {
        display: none;
    }
}
  
@media (min-width: 577px) and (max-width: 768px) {

    .slide {
        align-items: normal;
        &__bg-container {
            justify-content: center;
            align-items: normal;
    
            &_standard {
                flex-direction: row;
            }
            &_reverse {
                flex-direction: row;
            }
        }
    }
    .scroll-down-img {
        display: none;
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {
    .slide {
        align-items: normal;

        &__bg-container {
            justify-content: center;
            align-items: normal;
    
            &_standard {
                flex-direction: row;
            }
            &_reverse {
                flex-direction: row;
            }
        }
    }
    .scroll-down-img {
        display: none;
    }
}

// mobile end