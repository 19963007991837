.slide-img-effects {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.slide-img-effect {
    position: absolute;
    height: 100%;

    &:nth-child(1) {
        width: 100%;
        background-color: var(--color-watermelon);
    }

    &:nth-child(2) {
        width: 101%;
        background-color: var(--color-blue);
    }

    &_standard {
        // left: -1%;
        transform: scaleX(0) translateZ(0);
        transform-origin: 100% 50%;
    }

    &_reverse {
        // right: -1%;
        transform: scaleX(0) translateZ(0);
        transform-origin: 0% 50%;
    }

    &-blue--standard-animation {
        transform: scaleX(1) translateZ(0);
        transform-origin: 100% 50%;
        animation-name: slideImgBlueStandardAnimation;
        animation-duration: 0.75s;
        animation-timing-function: ease;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
    &-blue--reverse-animation {
        transform: scaleX(1) translateZ(0);
        transform-origin: 0% 50%;
        animation-name: slideImgBlueReverseAnimation;
        animation-duration: 0.75s;
        animation-timing-function: ease;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
    &-red--standard-animation {
        transform: scaleX(1) translateZ(0);
        transform-origin: 100% 50%;
        animation-name: slideImgRedStandardAnimation;
        animation-duration: 0.75s;
        animation-timing-function: ease-in;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
    &-red--reverse-animation {
        transform: scaleX(1) translateZ(0);
        transform-origin: 0% 50%;
        animation-name: slideImgRedReverseAnimation;
        animation-duration: 0.75s;
        animation-timing-function: ease-in;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
}

@keyframes slideImgBlueStandardAnimation {
    from {
        transform: scaleX(1) translateZ(0);
    }
    to {
        transform: scaleX(0) translateZ(0);
    }
}
@keyframes slideImgBlueReverseAnimation {
    from {
        transform: scaleX(1) translateZ(0);
    }
    to {
        transform: scaleX(0) translateZ(0);
    }
}
@keyframes slideImgRedStandardAnimation {
    from {
        transform: scaleX(1) translateZ(0);
    }
    to {
        transform: scaleX(0) translateZ(0);
    }
}
@keyframes slideImgRedReverseAnimation {
    from {
        transform: scaleX(1) translateZ(0);
    }
    to {
        transform: scaleX(0) translateZ(0);
    }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

}
  
@media (min-width: 769px) and (max-width: 992px) {

}
  
@media (min-width: 577px) and (max-width: 768px) {
    
}
  
@media (min-width: 0px) and (max-width: 576px) {

}

// mobile end