.slide-title {
    width: 100%;
    font-size: var(--font-size1);
    font-weight: normal;
    color: var(--color-white);
    hyphens: manual;
    word-wrap: break-word;
    // will-change: transform;
    transform: translateX(0%) translateZ(0);

    &--standard-animation {
        transform: translateX(-100%) translateZ(0);
        animation-name: slideTitleStandardAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
    &--reverse-animation {
        transform: translateX(100%) translateZ(0);
        animation-name: slideTitleReverseAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
}

@keyframes slideTitleStandardAnimation {
    from {
        transform: translateX(-100%)  translateZ(0);
    }

    to {
        transform: translateX(0)  translateZ(0);
    }
}

@keyframes slideTitleReverseAnimation {
    from {
        transform: translateX(100%)  translateZ(0);
    }

    to {
        transform: translateX(0)  translateZ(0);
    }
}

.slide-main-title {
    font-size: var(--font-size-main);
    color: var(--color-white);
}

// mobile-start

@media (min-width: 993px) and (max-width: 1200px) {

}
  
@media (min-width: 769px) and (max-width: 992px) {

}
  
@media (min-width: 577px) and (max-width: 768px) {

}
  
@media (min-width: 0px) and (max-width: 576px) {

}

// mobile-end