.slide-text-block {
    display: flex;
    flex-direction: column;
    width: 42%;
    overflow: hidden;
    z-index: 1;
    text-align: start;
    align-items: flex-start;

    &_standard {
        margin-left: var(--outer-horizontal-offset);
        padding-right: var(--internal-horizontal-offset);
    }

    &_reverse {
        margin-right: var(--outer-horizontal-offset);
        padding-left: var(--internal-horizontal-offset);
    }

    &--hide {
        transform: translateX(-100vw);
    }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

}

@media (min-width: 769px) and (max-width: 992px) {

}

@media (min-width: 577px) and (max-width: 768px) {

    .slide-text-block {
        position: absolute;
        left: 0;
        padding: 0;
        width: 70%;
        bottom: 20%;
    
        &_reverse {
            right: 0;
            left: auto;
            align-items: flex-end;
            text-align: end;
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .slide-text-block {
        position: absolute;
        left: 0;
        padding: 0;
        width: 70%;
        bottom: 20%;
    
        &_reverse {
            right: 0;
            left: auto;
            align-items: flex-end;
            text-align: end;
        }
    }
}

// mobile end