.slide-decoration-lines {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;

    &__item {
        display: block;
        width: 88px;
        height: 4px;
        border-radius: 5px;
        background-color: var(--color-watermelon);
        transform: translateX(0vw) translateZ(0);

        &--standard-normal-animation {
            transform: translateX(-100vw) translateZ(0);
            animation-name: slideDecorationLinesItemStandardNormalAnimation;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-delay: 0.75s;
            animation-fill-mode: forwards;
        }
        &--reverse-normal-animation {
            transform: translateX(100vw) translateZ(0);
            animation-name: slideDecorationLinesItemReverseNormalAnimation;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-delay: 0.75s;
            animation-fill-mode: forwards;
        }

        &--standard-alternate-animation {
            transform: translateX(-100vw) translateZ(0);
            animation-name: slideDecorationLinesItemStandardAlternateAnimation;
            animation-duration: 0.75s;
            animation-timing-function: ease-out;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
        }
        &--reverse-alternate-animation {
            transform: translateX(100vw) translateZ(0);
            animation-name: slideDecorationLinesItemReverseAlternateAnimation;
            animation-duration: 0.75s;
            animation-timing-function: ease-out;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
        }

        &:nth-child(1) {
            margin-bottom: 20px;
        }

        &_standard {
            &:nth-child(2) {
                margin-left: 50px;
            }
        }

        &_reverse {
            &:nth-child(2) {
                margin-left: 50px;
            }
        }
    }
}

@keyframes slideDecorationLinesItemStandardNormalAnimation {
    from {
        transform: translateX(-100vw) translateZ(0);
    }

    to {
        transform: translateX(0vw) translateZ(0);
    }
}

@keyframes slideDecorationLinesItemReverseNormalAnimation {
    from {
        transform: translateX(100vw) translateZ(0);
    }

    to {
        transform: translateX(0vw) translateZ(0);
    }
}

@keyframes slideDecorationLinesItemStandardAlternateAnimation {
    0% {
        transform: translateX(-100vw) translateZ(0);
    }

    66% {
        transform: translateX(2.5vw) translateZ(0);
    }

    100% {
        transform: translateX(0vw) translateZ(0);
    }
}

@keyframes slideDecorationLinesItemReverseAlternateAnimation {
    0% {
        transform: translateX(100vw) translateZ(0);
    }

    66% {
        transform: translateX(-2.5vw) translateZ(0);
    }

    100% {
        transform: translateX(0vw) translateZ(0);
    }
}


// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

}

@media (min-width: 769px) and (max-width: 992px) {

}

@media (min-width: 577px) and (max-width: 768px) {

    .slide-decoration-lines {
        &_standard {
            align-items: flex-start;
        }
    
        &_reverse {
            align-items: flex-end;
        }
        &__item {
            width: 60px;
            height: 2px;

            &:nth-child(1) {
                margin-bottom: 15px;
            }

            &_standard {
                &:nth-child(2) {
                    margin-left: 30px;
                }
            }
            &_reverse {
                &:nth-child(1) {
                    margin-right: 30px;
                }
            }
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .slide-decoration-lines {
        margin: 10px 0;
        &_standard {
            align-items: flex-start;
        }
    
        &_reverse {
            align-items: flex-end;
        }
        &__item {
            width: 40px;
            height: 2px;

            &:nth-child(1) {
                margin-bottom: 10px;
            }

            &_standard {
                &:nth-child(2) {
                    margin-left: 25px;
                }
            }
            &_reverse {
                &:nth-child(1) {
                    margin-right: 25px;
                }
            }
        }
    }
}

// mobile end