.slide-lead {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: var(--font-size-lead-text);
    font-weight: normal;
    color: var(--color-white);
    transform: translateX(0%) translateZ(0);

    &--standard-animation {
        transform: translateX(-100%) translateZ(0);
        animation-name: slideLeadStandardAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.65s;
        animation-fill-mode: forwards;
    }
    &--reverse-animation {
        transform: translateX(100%) translateZ(0);
        animation-name: slideLeadReverseAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.65s;
        animation-fill-mode: forwards;
    }
}

@keyframes slideLeadStandardAnimation {
    from {
        transform: translateX(-100%) translateZ(0);
    }

    to {
        transform: translateX(0%) translateZ(0);
    }
}

@keyframes slideLeadReverseAnimation {
    from {
        transform: translateX(100%) translateZ(0);
    }

    to {
        transform: translateX(0%) translateZ(0);
    }
}


// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

}

@media (min-width: 769px) and (max-width: 992px) {

}

@media (min-width: 577px) and (max-width: 768px) {

}
  
@media (min-width: 0px) and (max-width: 576px) {

}

// mobile end