.slide-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-top: 40px;
    overflow: hidden;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    transform: translateX(0%) translateZ(0);


    &--standard-animation {
        transform: translateX(-100%) translateZ(0);
        animation-name: slideLinkStandardAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.75s;
        animation-fill-mode: forwards;
    }
    &--reverse-animation {
        transform: translateX(100%) translateZ(0);
        animation-name: slideLinkReverseAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.75s;
        animation-fill-mode: forwards;
    }

    @keyframes slideLinkStandardAnimation {
        from {
            transform: translateX(-100%)  translateZ(0);
        }

        to {
            transform: translateX(0%)  translateZ(0);
        }
    }
    
    @keyframes slideLinkReverseAnimation {
        from {
            transform: translateX(100%)  translateZ(0);
        }

        to {
            transform: translateX(0%)  translateZ(0);
        }
    }

    & a {
        max-width: 200px;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        background-color: var(--color-watermelon);
        border-radius: 25px;
        font-size: var( --font-size-btn-text);
        color: var(--color-white);
        transition: transform 250ms ease 0s;

        &:hover {
            color: var(--color-white);
            transform: translateY(-4px);
        }
    }

    & span {
        max-width: 200px;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        background-color: var(--color-watermelon);
        border-radius: 25px;
        font-size: var( --font-size-btn-text);
        color: var(--color-white);
    }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 577px) and (max-width: 768px) {

    .slide-link {
        &_standard {
            justify-content: flex-start;
        }
        &_reverse {
            justify-content: flex-end;
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {
    .aaa {
        position: relative;
        width: 100vw;
    }

    .slide-link {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 10px;


        &_standard {
            margin-left: 5vw;
        }
        &_reverse {
            margin-right: 5vw;
        }
    }
}

// mobile end