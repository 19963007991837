.slide-img-container {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.slide-img {
    width: 100%;
    height: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(1) translateX(0%) translateZ(0);
    // background-image: var(--image);
    // background-image: url('data:image/jpg;base64,PCFET0NUWVBFIGh0bWw+CjxodG1sIGxhbmc9InJ1Ij4KICA8aGVhZD4KICAgIDxtZXRhIGNoYXJzZXQ9InV0Zi04IiAvPgogICAgPCEtLSA8bGluayByZWw9Imljb24iIGhyZWY9Ii9mYXZpY29uLmljbyIgLz4gLS0+CiAgICA8bWV0YSBuYW1lPSJ2aWV3cG9ydCIgY29udGVudD0id2lkdGg9ZGV2aWNlLXdpZHRoLCBpbml0aWFsLXNjYWxlPTEiIC8+CiAgICA8bWV0YSBuYW1lPSJ0aGVtZS1jb2xvciIgY29udGVudD0iIzAwMDAwMCIgLz4KICAgIDwhLS0gPG1ldGEKICAgICAgbmFtZT0iZGVzY3JpcHRpb24iIAogICAgICBjb250ZW50PSJXZWIgc2l0ZSBjcmVhdGVkIHVzaW5nIGNyZWF0ZS1yZWFjdC1hcHAiCiAgICAvPiAtLT4KICAgIDxsaW5rIHJlbD0iYXBwbGUtdG91Y2gtaWNvbiIgaHJlZj0iL2xvZ28xOTIucG5nIiAvPgogICAgPGxpbmsgcmVsPSJtYW5pZmVzdCIgaHJlZj0iL21hbmlmZXN0Lmpzb24iIC8+CiAgICA8bGluayByZWw9InByZWxvYWQiIGFzPSJmb250Ij4KICAgIDxsaW5rCiAgICAgIGhyZWY9ImZhdmljb24tbGlnaHQuaWNvIgogICAgICByZWw9Imljb24iCiAgICAgIG1lZGlhPSIocHJlZmVycy1jb2xvci1zY2hlbWU6IGxpZ2h0KSIKICAgIC8+CiAgICA8bGluawogICAgICBocmVmPSJmYXZpY29uLWRhcmsuaWNvIgogICAgICByZWw9Imljb24iCiAgICAgIG1lZGlhPSIocHJlZmVycy1jb2xvci1zY2hlbWU6IGRhcmspIgogICAgLz4KICAgIDwhLS0gPHRpdGxlPlJlYWN0IEFwcDwvdGl0bGU+IC0tPgogIDxzY3JpcHQgZGVmZXIgc3JjPSIvc3RhdGljL2pzL2J1bmRsZS5qcyI+PC9zY3JpcHQ+PC9oZWFkPgogIDxib2R5PgogICAgPG5vc2NyaXB0PllvdSBuZWVkIHRvIGVuYWJsZSBKYXZhU2NyaXB0IHRvIHJ1biB0aGlzIGFwcC48L25vc2NyaXB0PgogICAgPGRpdiBpZD0icm9vdCI+PC9kaXY+CiAgPC9ib2R5Pgo8L2h0bWw+Cg==');
    

    &_standard {
        margin-right: 8vw;
    }

    &_reverse {
        margin-left: 8vw;
    }

    &-header_standard {
        width: 100%;
        height: 100%;
        margin-right: 0;
    }

    &-header_reverse {
        width: 100%;
        height: 100%;
        margin-left: 0;
    }

    &--standard-animation {
        transform: scale(0.9) translateX(10%) translateZ(0);
        animation-name: slideImgStandardAnimation;
        animation-duration: 0.7s;
        animation-timing-function: ease-in-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
    &--reverse-animation {
        transform: scale(0.9) translateX(-10%) translateZ(0);
        animation-name: slideImgReverseAnimation;
        animation-duration: 0.7s;
        animation-timing-function: ease-in-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
}

@keyframes slideImgStandardAnimation {
    from {
        transform: scale(0.9) translateX(10%) translateZ(0);
    }
    to {
        transform: scale(1) translateX(0%) translateX(0);
    }
}
@keyframes slideImgReverseAnimation {
    from {
        transform: scale(0.9) translateX(-10%) translateZ(0);
    }
    to {
        transform: scale(1) translateX(0%) translateX(0);
    }
}

// @keyframes slideImgStandardAnimationMobile {
//     from {
//         transform: scale(0.9) translateX(10%) translateY(10%);
//     }
//     to {
//         transform: scale(1) translateX(0) translateY(10%);
//     }
// }
// @keyframes slideImgReverseAnimationMobile {
//     from {
//         transform: scale(0.9) translateX(-10%) translateY(10%);
//     }
//     to {
//         transform: scale(1) translateX(0) translateY(10%);
//     }
// }

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

}
  
@media (min-width: 769px) and (max-width: 992px) {

}
  
@media (min-width: 577px) and (max-width: 768px) {
    
    .slide-img-container {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .slide-img {
        width: 76%;
        height: 80%;
        margin: 0;


        &-header_standard {
            margin: 0;
            transform: scale(1.5);
        }

        &-header_reverse {
            margin: 0;
            transform: scale(1.5);
        }

        // &--standard-animation {
        //     transform: scale(0.8) translateX(20%) translateY(0);
        //     animation-name: slideImgStandardAnimationMobile;
        // }
        // &--reverse-animation {
        //     transform: scale(0.8) translateX(-20%) translateY(0);
        //     animation-name: slideImgReverseAnimationMobile;
        // }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .slide-img-container {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .slide-img {
        width: 76%;
        height: 80%;
        margin: 0;


        &-header_standard {
            margin: 0;
            transform: scale(1.5);
        }

        &-header_reverse {
            margin: 0;
            transform: scale(1.5);
        }

        // &--standard-animation {
        //     transform: scale(0.8) translateX(20%) translateY(0);
        //     animation-name: slideImgStandardAnimationMobile;
        // }
        // &--reverse-animation {
        //     transform: scale(0.8) translateX(-20%) translateY(0);
        //     animation-name: slideImgReverseAnimationMobile;
        // }
    }
}

// mobile end